
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { onBeforeRouteLeave } from "vue-router";

import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-account",
  components: {
    Dropdown3,
  },
  setup() {
    const store = useStore();

    onBeforeRouteLeave(() => {
      store.commit(Mutations.PURGE_LOCAL_PROFILE);
    });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const currentUserProfile = computed(() => {
      return store.getters.currentUserProfile;
    });

    return {
      currentUser,
      currentUserProfile,
      store,
    };
  },
});
